import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '../components/App/Layout'
import Navbar from '../components/Common/Navbar'
import PageBanner from '../components/Common/PageBanner'
import { Link } from 'gatsby'
import Footer from '../components/Common/Footer'

const ComponentName = ({ data }) => {
  const {
    title,
    content,
    categories,
    tags,
    BlogMeta,
    TwitterMeta,
    image,
    date,
  } = data.blog

  return (
    <Layout metaTags={BlogMeta} twitterCard={TwitterMeta}>
      <Navbar />

      <PageBanner
        pageTitle={title}
        homePageText="Blog"
        homePageUrl="/blog"
        activePageText={title}
        date={date}
        categories={categories}
      />

      <section className="blog-details-area">
        <div className="container">
          <div className="row">
            <div className="col-12 pb-70">
              <GatsbyImage
                alt={title}
                image={image.childImageSharp.gatsbyImageData}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="blog-details-desc">
                <ReactMarkdown source={content} />
              </div>
            </div>
            {tags ? (
              <div className="widget-area m-0 pb-100">
                <div className="tagcloud">
                  {tags.map(items => {
                    return (
                      <Link to={`/tag/${items.slug}`}>{items.name}</Link>
                    )
                  })}
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </section>

      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query GetSingleBlog($slug: String) {
    blog: strapiBlog(slug: { eq: $slug }) {
      title
      content
      image {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      categories {
        category
        slug
      }
      tags {
        name
        slug
      }
      BlogMeta {
        title
        description
        keywords
      }
      TwitterMeta {
        card
      }
      date: date(formatString: "MMMM D, YYYY")
    }
  }
`

export default ComponentName
